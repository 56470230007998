.blog-detail {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    background: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.blog-header {
    text-align: center;
    margin-bottom: 20px;
}

.blog-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2c3e50;
}

.blog-date {
    font-size: 0.9rem;
    color: #7f8c8d;
}

.blog-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 20px 0;
}

.blog-content {
    font-size: 1.2rem;
    line-height: 1.8;
}

.blog-content h2 {
    font-size: 1.5rem;
    color: #2c3e50;
    margin-top: 20px;
}

.blog-content ul {
    margin: 20px 0;
    padding-left: 20px;
}

.blog-content ul li {
    margin-bottom: 10px;
}

.blog-footer {
    margin-top: 40px;
    text-align: center;
}

.social-icons a {
    margin: 0 10px;
    color: #0077b5;
    text-decoration: none;
    transition: color 0.3s;
}

.social-icons a:hover {
    color: #005582;
}
