.comparison-container {
  max-width: 900px;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.comparison-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #666;
}

.comparison-subtitle {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}

.comparison-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.comparison-table th,
.comparison-table td {
  padding: 15px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 16px;
}

.comparison-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.comparison-table .highlight {
  background-color: #d4edda;
  font-weight: bold;
  color: #155724;
}

.hovered-row {
  background-color: #f9f9f9;
}

.hover-info {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

.cta-container {
  margin-top: 20px;
}

.cta-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.cta-button:hover {
  background-color: #218838;
}
