.modal-body section h1 {
  font-size: 1.25rem;
  color: #000;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
}

.modal-body section .tax-form-icon {
  width: 25px;
}

.modal-body section h5 {
  font-weight: 500;
  font-size: 0.8rem;
}

.modal-body .cta-button {
  margin: 1.5rem auto 0;
  width: 125px;
  margin-left: calc(100% - 125px);
}

.modal-container .modal-close-btn {
  background: transparent;
  outline: none;
  border: none;
  position: absolute;
  top: 2.5em;
  right: 2em;
  cursor: pointer;
}

.modal-container .modal-close-btn svg {
  width: 20px;
  height: 20px;
}

.nice-form-group {
  --nf-input-size: 1rem;
  --nf-input-font-size: calc(var(--nf-input-size) * 0.875);
  --nf-small-font-size: calc(var(--nf-input-size) * 0.875);
  --nf-input-font-family: inherit;
  --nf-label-font-family: inherit;
  --nf-input-color: #20242f;
  --nf-input-border-radius: 0.25rem;
  --nf-input-placeholder-color: #929292;
  --nf-input-border-color: #c0c4c9;
  --nf-input-border-width: 1px;
  --nf-input-border-style: solid;
  --nf-input-border-bottom-width: 2px;
  --nf-input-focus-border-color: #3b4ce2;
  --nf-input-background-color: #f9fafb;
  --nf-invalid-input-border-color: var(--nf-input-border-color);
  --nf-invalid-input-background-color: var(--nf-input-background-color);
  --nf-invalid-input-color: var(--nf-input-color);
  --nf-valid-input-border-color: var(--nf-input-border-color);
  --nf-valid-input-background-color: var(--nf-input-background-color);
  --nf-valid-input-color: inherit;
  --nf-invalid-input-border-bottom-color: red;
  --nf-valid-input-border-bottom-color: green;
  --nf-label-font-size: var(--nf-small-font-size);
  --nf-label-color: #374151;
  --nf-label-font-weight: 500;
  --nf-slider-track-background: #dfdfdf;
  --nf-slider-track-height: 0.25rem;
  --nf-slider-thumb-size: calc(var(--nf-slider-track-height) * 4);
  --nf-slider-track-border-radius: var(--nf-slider-track-height);
  --nf-slider-thumb-border-width: 2px;
  --nf-slider-thumb-border-focus-width: 1px;
  --nf-slider-thumb-border-color: #fff;
  --nf-slider-thumb-background: var(--nf-input-focus-border-color);
  display: block;
  margin-top: calc(var(--nf-input-size) * 1.5);
  line-height: 1;
  white-space: nowrap;
  --switch-orb-size: var(--nf-input-size);
  --switch-orb-offset: calc(var(--nf-input-border-width) * 2);
  --switch-width: calc(var(--nf-input-size) * 2.5);
  --switch-height: calc(var(--nf-input-size) * 1.25 + var(--switch-orb-offset));
}
.nice-form-group > label {
  font-weight: var(--nf-label-font-weight);
  display: block;
  color: var(--nf-label-color);
  font-size: var(--nf-label-font-size);
  font-family: var(--nf-label-font-family);
  margin-bottom: calc(var(--nf-input-size) / 2);
  white-space: normal;
}
.nice-form-group > label + small {
  font-style: normal;
}
.nice-form-group small {
  font-family: var(--nf-input-font-family);
  display: block;
  font-weight: 400;
  opacity: 0.75;
  font-size: var(--nf-small-font-size);
  margin-bottom: calc(var(--nf-input-size) * 0.75);
}
.nice-form-group small:last-child {
  margin-bottom: 0;
}
.nice-form-group > legend {
  font-weight: var(--nf-label-font-weight);
  display: block;
  color: var(--nf-label-color);
  font-size: var(--nf-label-font-size);
  font-family: var(--nf-label-font-family);
  margin-bottom: calc(var(--nf-input-size) / 5);
}
.nice-form-group > .nice-form-group {
  margin-top: calc(var(--nf-input-size) / 2);
}
.nice-form-group > input[type="checkbox"],
.nice-form-group > input[type="date"],
.nice-form-group > input[type="email"],
.nice-form-group > input[type="month"],
.nice-form-group > input[type="number"],
.nice-form-group > input[type="password"],
.nice-form-group > input[type="radio"],
.nice-form-group > input[type="search"],
.nice-form-group > input[type="tel"],
.nice-form-group > input[type="text"],
.nice-form-group > input[type="time"],
.nice-form-group > input[type="url"],
.nice-form-group > input[type="week"],
.nice-form-group > select,
.nice-form-group > textarea {
  background: var(--nf-input-background-color);
  font-family: inherit;
  font-size: var(--nf-input-font-size);
  border-bottom-width: var(--nf-input-border-width);
  font-family: var(--nf-input-font-family);
  box-shadow: none;
  border-radius: var(--nf-input-border-radius);
  border: var(--nf-input-border-width) var(--nf-input-border-style)
    var(--nf-input-border-color);
  border-bottom: var(--nf-input-border-bottom-width)
    var(--nf-input-border-style) var(--nf-input-border-color);
  color: var(--nf-input-color);
  width: 100%;
  padding: calc(var(--nf-input-size) * 0.5);
  height: calc(var(--nf-input-size) * 2.45);
  line-height: normal;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all 0.15s ease-out;
  --icon-padding: calc(var(--nf-input-size) * 2.25);
  --icon-background-offset: calc(var(--nf-input-size) * 0.75);
}
.nice-form-group
  > input[type="checkbox"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="date"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="email"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="month"]:required:not(:placeholder-shown):invalid,
.nice-form-group
  > input[type="number"]:required:not(:placeholder-shown):invalid,
.nice-form-group
  > input[type="password"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="radio"]:required:not(:placeholder-shown):invalid,
.nice-form-group
  > input[type="search"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="tel"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="text"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="time"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="url"]:required:not(:placeholder-shown):invalid,
.nice-form-group > input[type="week"]:required:not(:placeholder-shown):invalid,
.nice-form-group > select:required:not(:placeholder-shown):invalid,
.nice-form-group > textarea:required:not(:placeholder-shown):invalid {
  background-color: var(--nf-invalid-input-background-color);
  border-bottom-color: var(--nf-valid-input-border-color);
  border-color: var(--nf-valid-input-border-color)
    var(--nf-valid-input-border-color)
    var(--nf-invalid-input-border-bottom-color);
  color: var(--nf-invalid-input-color);
}
.nice-form-group
  > input[type="checkbox"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="date"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="email"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="month"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="number"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="password"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="radio"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="search"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="tel"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="text"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="time"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="url"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group
  > input[type="week"]:required:not(:placeholder-shown):invalid:focus,
.nice-form-group > select:required:not(:placeholder-shown):invalid:focus,
.nice-form-group > textarea:required:not(:placeholder-shown):invalid:focus {
  background-color: var(--nf-input-background-color);
  border-color: var(--nf-input-border-color);
  color: var(--nf-input-color);
}
.nice-form-group
  > input[type="checkbox"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="date"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="email"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="month"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="number"]:required:not(:placeholder-shown):valid,
.nice-form-group
  > input[type="password"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="radio"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="search"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="tel"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="text"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="time"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="url"]:required:not(:placeholder-shown):valid,
.nice-form-group > input[type="week"]:required:not(:placeholder-shown):valid,
.nice-form-group > select:required:not(:placeholder-shown):valid,
.nice-form-group > textarea:required:not(:placeholder-shown):valid {
  background-color: var(--nf-valid-input-background-color);
  border-bottom-color: var(--nf-valid-input-border-color);
  border-color: var(--nf-valid-input-border-color)
    var(--nf-valid-input-border-color) var(--nf-valid-input-border-bottom-color);
  color: var(--nf-valid-input-color);
}
.nice-form-group > input[type="checkbox"]:disabled,
.nice-form-group > input[type="date"]:disabled,
.nice-form-group > input[type="email"]:disabled,
.nice-form-group > input[type="month"]:disabled,
.nice-form-group > input[type="number"]:disabled,
.nice-form-group > input[type="password"]:disabled,
.nice-form-group > input[type="radio"]:disabled,
.nice-form-group > input[type="search"]:disabled,
.nice-form-group > input[type="tel"]:disabled,
.nice-form-group > input[type="text"]:disabled,
.nice-form-group > input[type="time"]:disabled,
.nice-form-group > input[type="url"]:disabled,
.nice-form-group > input[type="week"]:disabled,
.nice-form-group > select:disabled,
.nice-form-group > textarea:disabled {
  cursor: not-allowed;
  opacity: 0.75;
}
.nice-form-group > input[type="checkbox"]::-webkit-input-placeholder,
.nice-form-group > input[type="date"]::-webkit-input-placeholder,
.nice-form-group > input[type="email"]::-webkit-input-placeholder,
.nice-form-group > input[type="month"]::-webkit-input-placeholder,
.nice-form-group > input[type="number"]::-webkit-input-placeholder,
.nice-form-group > input[type="password"]::-webkit-input-placeholder,
.nice-form-group > input[type="radio"]::-webkit-input-placeholder,
.nice-form-group > input[type="search"]::-webkit-input-placeholder,
.nice-form-group > input[type="tel"]::-webkit-input-placeholder,
.nice-form-group > input[type="text"]::-webkit-input-placeholder,
.nice-form-group > input[type="time"]::-webkit-input-placeholder,
.nice-form-group > input[type="url"]::-webkit-input-placeholder,
.nice-form-group > input[type="week"]::-webkit-input-placeholder,
.nice-form-group > select::-webkit-input-placeholder,
.nice-form-group > textarea::-webkit-input-placeholder {
  color: var(--nf-input-placeholder-color);
  letter-spacing: 0;
}
.nice-form-group > input[type="checkbox"]:-ms-input-placeholder,
.nice-form-group > input[type="date"]:-ms-input-placeholder,
.nice-form-group > input[type="email"]:-ms-input-placeholder,
.nice-form-group > input[type="month"]:-ms-input-placeholder,
.nice-form-group > input[type="number"]:-ms-input-placeholder,
.nice-form-group > input[type="password"]:-ms-input-placeholder,
.nice-form-group > input[type="radio"]:-ms-input-placeholder,
.nice-form-group > input[type="search"]:-ms-input-placeholder,
.nice-form-group > input[type="tel"]:-ms-input-placeholder,
.nice-form-group > input[type="text"]:-ms-input-placeholder,
.nice-form-group > input[type="time"]:-ms-input-placeholder,
.nice-form-group > input[type="url"]:-ms-input-placeholder,
.nice-form-group > input[type="week"]:-ms-input-placeholder,
.nice-form-group > select:-ms-input-placeholder,
.nice-form-group > textarea:-ms-input-placeholder {
  color: var(--nf-input-placeholder-color);
  letter-spacing: 0;
}
.nice-form-group > input[type="checkbox"]:-moz-placeholder,
.nice-form-group > input[type="checkbox"]::-moz-placeholder,
.nice-form-group > input[type="date"]:-moz-placeholder,
.nice-form-group > input[type="date"]::-moz-placeholder,
.nice-form-group > input[type="email"]:-moz-placeholder,
.nice-form-group > input[type="email"]::-moz-placeholder,
.nice-form-group > input[type="month"]:-moz-placeholder,
.nice-form-group > input[type="month"]::-moz-placeholder,
.nice-form-group > input[type="number"]:-moz-placeholder,
.nice-form-group > input[type="number"]::-moz-placeholder,
.nice-form-group > input[type="password"]:-moz-placeholder,
.nice-form-group > input[type="password"]::-moz-placeholder,
.nice-form-group > input[type="radio"]:-moz-placeholder,
.nice-form-group > input[type="radio"]::-moz-placeholder,
.nice-form-group > input[type="search"]:-moz-placeholder,
.nice-form-group > input[type="search"]::-moz-placeholder,
.nice-form-group > input[type="tel"]:-moz-placeholder,
.nice-form-group > input[type="tel"]::-moz-placeholder,
.nice-form-group > input[type="text"]:-moz-placeholder,
.nice-form-group > input[type="text"]::-moz-placeholder,
.nice-form-group > input[type="time"]:-moz-placeholder,
.nice-form-group > input[type="time"]::-moz-placeholder,
.nice-form-group > input[type="url"]:-moz-placeholder,
.nice-form-group > input[type="url"]::-moz-placeholder,
.nice-form-group > input[type="week"]:-moz-placeholder,
.nice-form-group > input[type="week"]::-moz-placeholder,
.nice-form-group > select:-moz-placeholder,
.nice-form-group > select::-moz-placeholder,
.nice-form-group > textarea:-moz-placeholder,
.nice-form-group > textarea::-moz-placeholder {
  color: var(--nf-input-placeholder-color);
  letter-spacing: 0;
}
.nice-form-group > input[type="checkbox"]:focus,
.nice-form-group > input[type="date"]:focus,
.nice-form-group > input[type="email"]:focus,
.nice-form-group > input[type="month"]:focus,
.nice-form-group > input[type="number"]:focus,
.nice-form-group > input[type="password"]:focus,
.nice-form-group > input[type="radio"]:focus,
.nice-form-group > input[type="search"]:focus,
.nice-form-group > input[type="tel"]:focus,
.nice-form-group > input[type="text"]:focus,
.nice-form-group > input[type="time"]:focus,
.nice-form-group > input[type="url"]:focus,
.nice-form-group > input[type="week"]:focus,
.nice-form-group > select:focus,
.nice-form-group > textarea:focus {
  outline: none;
  border-color: var(--nf-input-focus-border-color);
}
.nice-form-group > input[type="checkbox"] + small,
.nice-form-group > input[type="date"] + small,
.nice-form-group > input[type="email"] + small,
.nice-form-group > input[type="month"] + small,
.nice-form-group > input[type="number"] + small,
.nice-form-group > input[type="password"] + small,
.nice-form-group > input[type="radio"] + small,
.nice-form-group > input[type="search"] + small,
.nice-form-group > input[type="tel"] + small,
.nice-form-group > input[type="text"] + small,
.nice-form-group > input[type="time"] + small,
.nice-form-group > input[type="url"] + small,
.nice-form-group > input[type="week"] + small,
.nice-form-group > select + small,
.nice-form-group > textarea + small {
  margin-top: 0.5rem;
}
.nice-form-group > input[type="checkbox"].icon-left,
.nice-form-group > input[type="date"].icon-left,
.nice-form-group > input[type="email"].icon-left,
.nice-form-group > input[type="month"].icon-left,
.nice-form-group > input[type="number"].icon-left,
.nice-form-group > input[type="password"].icon-left,
.nice-form-group > input[type="radio"].icon-left,
.nice-form-group > input[type="search"].icon-left,
.nice-form-group > input[type="tel"].icon-left,
.nice-form-group > input[type="text"].icon-left,
.nice-form-group > input[type="time"].icon-left,
.nice-form-group > input[type="url"].icon-left,
.nice-form-group > input[type="week"].icon-left,
.nice-form-group > select.icon-left,
.nice-form-group > textarea.icon-left {
  background-position: left var(--icon-background-offset) bottom 50%;
  padding-left: var(--icon-padding);
  background-size: var(--nf-input-size);
}
.nice-form-group > input[type="checkbox"].icon-right,
.nice-form-group > input[type="date"].icon-right,
.nice-form-group > input[type="email"].icon-right,
.nice-form-group > input[type="month"].icon-right,
.nice-form-group > input[type="number"].icon-right,
.nice-form-group > input[type="password"].icon-right,
.nice-form-group > input[type="radio"].icon-right,
.nice-form-group > input[type="search"].icon-right,
.nice-form-group > input[type="tel"].icon-right,
.nice-form-group > input[type="text"].icon-right,
.nice-form-group > input[type="time"].icon-right,
.nice-form-group > input[type="url"].icon-right,
.nice-form-group > input[type="week"].icon-right,
.nice-form-group > select.icon-right,
.nice-form-group > textarea.icon-right {
  background-position: right var(--icon-background-offset) bottom 50%;
  padding-right: var(--icon-padding);
  background-size: var(--nf-input-size);
}
.nice-form-group > input[type="checkbox"]:-webkit-autofill,
.nice-form-group > input[type="date"]:-webkit-autofill,
.nice-form-group > input[type="email"]:-webkit-autofill,
.nice-form-group > input[type="month"]:-webkit-autofill,
.nice-form-group > input[type="number"]:-webkit-autofill,
.nice-form-group > input[type="password"]:-webkit-autofill,
.nice-form-group > input[type="radio"]:-webkit-autofill,
.nice-form-group > input[type="search"]:-webkit-autofill,
.nice-form-group > input[type="tel"]:-webkit-autofill,
.nice-form-group > input[type="text"]:-webkit-autofill,
.nice-form-group > input[type="time"]:-webkit-autofill,
.nice-form-group > input[type="url"]:-webkit-autofill,
.nice-form-group > input[type="week"]:-webkit-autofill,
.nice-form-group > select:-webkit-autofill,
.nice-form-group > textarea:-webkit-autofill {
  padding: calc(var(--nf-input-size) * 0.75) !important;
}
.nice-form-group > input[type="search"]:placeholder-shown {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-search'%3E%3Ccircle cx='11' cy='11' r='8'/%3E%3Cpath d='M21 21l-4.35-4.35'/%3E%3C/svg%3E");
  background-position: left calc(var(--nf-input-size) * 0.75) bottom 50%;
  padding-left: calc(var(--nf-input-size) * 2.25);
  background-size: var(--nf-input-size);
  background-repeat: no-repeat;
}
.nice-form-group > input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  width: var(--nf-input-size);
  height: var(--nf-input-size);
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cpath d='M18 6L6 18M6 6l12 12'/%3E%3C/svg%3E");
}
.nice-form-group > input[type="search"]:focus {
  padding-left: calc(var(--nf-input-size) * 0.75);
  background-position: left calc(var(--nf-input-size) * -1) bottom 50%;
}
.nice-form-group > input[type="email"][class^="icon"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-at-sign'%3E%3Ccircle cx='12' cy='12' r='4'/%3E%3Cpath d='M16 8v5a3 3 0 006 0v-1a10 10 0 10-3.92 7.94'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
.nice-form-group > input[type="tel"][class^="icon"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-phone'%3E%3Cpath d='M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
.nice-form-group > input[type="url"][class^="icon"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-link'%3E%3Cpath d='M10 13a5 5 0 007.54.54l3-3a5 5 0 00-7.07-7.07l-1.72 1.71'/%3E%3Cpath d='M14 11a5 5 0 00-7.54-.54l-3 3a5 5 0 007.07 7.07l1.71-1.71'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
.nice-form-group > input[type="password"] {
  letter-spacing: 2px;
}
.nice-form-group > input[type="password"][class^="icon"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-lock'%3E%3Crect x='3' y='11' width='18' height='11' rx='2' ry='2'/%3E%3Cpath d='M7 11V7a5 5 0 0110 0v4'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
.nice-form-group > input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  cursor: pointer;
}
.nice-form-group > input[type="range"]:focus {
  outline: none;
}
.nice-form-group > input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: var(--nf-slider-track-height);
  background: var(--nf-slider-track-background);
  border-radius: var(--nf-slider-track-border-radius);
}
.nice-form-group > input[type="range"]::-moz-range-track {
  width: 100%;
  height: var(--nf-slider-track-height);
  background: var(--nf-slider-track-background);
  border-radius: var(--nf-slider-track-border-radius);
}
.nice-form-group > input[type="range"]::-webkit-slider-thumb {
  height: var(--nf-slider-thumb-size);
  width: var(--nf-slider-thumb-size);
  border-radius: var(--nf-slider-thumb-size);
  background: var(--nf-slider-thumb-background);
  border: 0;
  border: var(--nf-slider-thumb-border-width) solid
    var(--nf-slider-thumb-border-color);
  -webkit-appearance: none;
  appearance: none;
  margin-top: calc(
    var(--nf-slider-track-height) * 0.5 - var(--nf-slider-thumb-size) * 0.5
  );
}
.nice-form-group > input[type="range"]::-moz-range-thumb {
  height: var(--nf-slider-thumb-size);
  width: var(--nf-slider-thumb-size);
  border-radius: var(--nf-slider-thumb-size);
  background: var(--nf-slider-thumb-background);
  border: 0;
  border: var(--nf-slider-thumb-border-width) solid
    var(--nf-slider-thumb-border-color);
  -moz-appearance: none;
  appearance: none;
  box-sizing: border-box;
}
.nice-form-group > input[type="range"]:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 var(--nf-slider-thumb-border-focus-width)
    var(--nf-slider-thumb-background);
}
.nice-form-group > input[type="range"]:focus::-moz-range-thumb {
  box-shadow: 0 0 0 var(--nf-slider-thumb-border-focus-width)
    var(--nf-slider-thumb-background);
}
.nice-form-group > input[type="color"] {
  border: var(--nf-input-border-width) solid var(--nf-input-border-color);
  border-bottom-width: var(--nf-input-border-bottom-width);
  height: calc(var(--nf-input-size) * 2);
  border-radius: var(--nf-input-border-radius);
  padding: calc(var(--nf-input-border-width) * 2);
}
.nice-form-group > input[type="color"]:focus {
  outline: none;
  border-color: var(--nf-input-focus-border-color);
}
.nice-form-group > input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 5%;
}
.nice-form-group > input[type="color"]::-moz-color-swatch {
  border-radius: calc(var(--nf-input-border-radius) / 2);
  border: none;
}
.nice-form-group > input[type="color"]::-webkit-color-swatch {
  border-radius: calc(var(--nf-input-border-radius) / 2);
  border: none;
}
.nice-form-group > input[type="number"] {
  width: auto;
}
.nice-form-group > input[type="date"],
.nice-form-group > input[type="month"],
.nice-form-group > input[type="week"] {
  min-width: 14em;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-calendar'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'/%3E%3Cpath d='M16 2v4M8 2v4M3 10h18'/%3E%3C/svg%3E");
}
.nice-form-group > input[type="time"] {
  min-width: 6em;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-clock'%3E%3Ccircle cx='12' cy='12' r='10'/%3E%3Cpath d='M12 6v6l4 2'/%3E%3C/svg%3E");
}
.nice-form-group > input[type="date"],
.nice-form-group > input[type="month"],
.nice-form-group > input[type="time"],
.nice-form-group > input[type="week"] {
  background-position: right calc(var(--nf-input-size) * 0.75) bottom 50%;
  background-repeat: no-repeat;
  background-size: var(--nf-input-size);
  width: auto;
}
.nice-form-group > input[type="date"]::-webkit-calendar-picker-indicator,
.nice-form-group > input[type="date"]::-webkit-inner-spin-button,
.nice-form-group > input[type="month"]::-webkit-calendar-picker-indicator,
.nice-form-group > input[type="month"]::-webkit-inner-spin-button,
.nice-form-group > input[type="time"]::-webkit-calendar-picker-indicator,
.nice-form-group > input[type="time"]::-webkit-inner-spin-button,
.nice-form-group > input[type="week"]::-webkit-calendar-picker-indicator,
.nice-form-group > input[type="week"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  cursor: pointer;
  opacity: 0;
}
@-moz-document url-prefix() {
  .nice-form-group > input[type="date"],
  .nice-form-group > input[type="month"],
  .nice-form-group > input[type="time"],
  .nice-form-group > input[type="week"] {
    min-width: auto;
    width: auto;
    background-image: none;
  }
}
.nice-form-group > textarea {
  height: auto;
}
.nice-form-group > input[type="checkbox"],
.nice-form-group > input[type="radio"] {
  width: var(--nf-input-size);
  height: var(--nf-input-size);
  padding: inherit;
  margin: 0;
  display: inline-block;
  vertical-align: top;
  border-radius: calc(var(--nf-input-border-radius) / 2);
  border-width: var(--nf-input-border-width);
  cursor: pointer;
  background-position: 50%;
}
.nice-form-group > input[type="checkbox"]:focus:not(:checked),
.nice-form-group > input[type="radio"]:focus:not(:checked) {
  border: var(--nf-input-border-width) solid var(--nf-input-focus-border-color);
  outline: none;
}
.nice-form-group > input[type="checkbox"]:hover,
.nice-form-group > input[type="radio"]:hover {
  border: var(--nf-input-border-width) solid var(--nf-input-focus-border-color);
}
.nice-form-group > input[type="checkbox"] + label,
.nice-form-group > input[type="radio"] + label {
  display: inline-block;
  margin-bottom: 0;
  padding-left: calc(var(--nf-input-size) / 2.5);
  font-weight: 400;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  max-width: calc(100% - var(--nf-input-size) * 2);
  line-height: normal;
}
.nice-form-group > input[type="checkbox"] + label > small,
.nice-form-group > input[type="radio"] + label > small {
  margin-top: calc(var(--nf-input-size) / 4);
}
.nice-form-group > input[type="checkbox"]:checked {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpath d='M20 6L9 17l-5-5'/%3E%3C/svg%3E")
    no-repeat 50%/85%;
  background-color: var(--nf-input-focus-border-color);
  border-color: var(--nf-input-focus-border-color);
}
.nice-form-group > input[type="radio"] {
  border-radius: 100%;
}
.nice-form-group > input[type="radio"]:checked {
  background-color: var(--nf-input-focus-border-color);
  border-color: var(--nf-input-focus-border-color);
  box-shadow: inset 0 0 0 3px #fff;
}
.nice-form-group > input[type="checkbox"].switch {
  width: var(--switch-width);
  height: var(--switch-height);
  border-radius: var(--switch-height);
  position: relative;
}
.nice-form-group > input[type="checkbox"].switch:after {
  background: var(--nf-input-border-color);
  border-radius: var(--switch-orb-size);
  height: var(--switch-orb-size);
  left: var(--switch-orb-offset);
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: var(--switch-orb-size);
  content: "";
  transition: all 0.2s ease-out;
}
.nice-form-group > input[type="checkbox"].switch + label {
  margin-top: calc(var(--switch-height) / 8);
}
.nice-form-group > input[type="checkbox"].switch:checked {
  background: none;
  background-position: 0 0;
  background-color: var(--nf-input-focus-border-color);
}
.nice-form-group > input[type="checkbox"].switch:checked:after {
  -webkit-transform: translateY(-50%)
    translateX(calc(var(--switch-width) / 2 - var(--switch-orb-offset)));
  transform: translateY(-50%)
    translateX(calc(var(--switch-width) / 2 - var(--switch-orb-offset)));
  background: #fff;
}
.nice-form-group > input[type="file"] {
  background: rgba(0, 0, 0, 0.025);
  padding: var(--nf-input-size);
  display: block;
  width: 100%;
  border-radius: var(--nf-input-border-radius);
  border: 1px dashed var(--nf-input-border-color);
  outline: none;
  cursor: pointer;
}
.nice-form-group > input[type="file"]:focus,
.nice-form-group > input[type="file"]:hover {
  border-color: var(--nf-input-focus-border-color);
}
.nice-form-group > input[type="file"]::file-selector-button {
  background: var(--nf-input-focus-border-color);
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.5rem;
  border-radius: var(--nf-input-border-radius);
  color: #fff;
  margin-right: 1rem;
  outline: none;
  font-family: var(--nf-input-font-family);
  cursor: pointer;
}
.nice-form-group > input[type="file"]::-webkit-file-upload-button {
  background: var(--nf-input-focus-border-color);
  border: 0;
  -webkit-appearance: none;
  appearance: none;
  padding: 0.5rem;
  border-radius: var(--nf-input-border-radius);
  color: #fff;
  margin-right: 1rem;
  outline: none;
  font-family: var(--nf-input-font-family);
  cursor: pointer;
}
.nice-form-group > select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-position: right calc(var(--nf-input-size) * 0.75) bottom 50%;
  background-repeat: no-repeat;
  background-size: var(--nf-input-size);
}
