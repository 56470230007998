.pricing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.toggle-button {
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-button.active {
  background-color: #264653;
}

.toggle-circle {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle-button.active .toggle-circle {
  transform: translateX(25px);
}

.plans-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  max-width: 1000px;
  width: 100%;
}

.plan-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.plan-card.popular {
  border-color: #264653;
}

.popular-badge {
  position: absolute;
  top: -10px;
  right: 10px;
  background-color: #6c47ff;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
}

.plan-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.plan-price {
  font-size: 2rem;
  font-weight: bold;
  margin: 10px 0;
}

.original-price {
  font-size: 1rem;
  text-decoration: line-through;
  color: gray;
  margin-right: 5px;
}

.plan-features {
  list-style: none;
  padding: 0;
}

.feature-item {
  font-size: 1rem;
  margin: 5px 0;
}

.plan-button {
  background-color: #264653;
  color: white;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
}

.plan-button:hover {
  background-color: #5436d1;
}
