.about-section {
  line-height: 1.6;
  padding-bottom: 40px;
}

.about-section h2 {
  font-size: 1.5rem;
  color: #2a9d8f;
  margin-top: 20px;
  margin-bottom: 10px;
}

.about-section ul {
  padding-left: 20px;
  margin-bottom: 20px;
}

.about-section ul li {
  margin-bottom: 10px;
}

.about-section strong {
  color: #264653;
}

.about-section hr {
  border: none;
  border-top: 1px solid #ddd;
  margin: 20px 0;
}

.about-section address {
  font-style: normal;
  font-weight: bold;
}
