/* Grid Layout */
.calculator-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 40px;
  align-items: center;
  max-width: 1100px;
  margin: auto;
  padding: 40px 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 10px;
}

.input-container h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.input-container label {
  font-size: 1rem;
  font-weight: bold;
}

.input-container input {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 2px solid #ddd;
  border-radius: 6px;
  text-align: center;
  background: white;
}

.inline-inputs {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.inline-inputs div {
  flex: 1;
}

/* Chart Styling */
.chart-container {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.summary {
  text-align: left;
  margin-top: 20px;
}

.summary p {
  font-size: 1.2rem;
  margin: 5px 0;
}

.summary strong {
  font-weight: bold;
}

.loss {
  color: red;
  font-weight: bold;
  font-size: 1.5rem;
}

/* Responsive Design */
@media (max-width: 900px) {
  .calculator-grid {
    grid-template-columns: 1fr; /* Stack on smaller screens */
  }
}
