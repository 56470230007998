/* Full-page styles for the stats section */
.white-underline-title {
  text-align: center; /* Centers the text horizontally */
  font-size: 2.5rem; /* Adjust font size as needed */
  font-weight: bold;
  color: #2c3e50; /* Text color for contrast */
  padding: 60px 0; /* Adds internal spacing to the section */
}

.white-underline-title::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background-color: #2a9d8f; /* Accent color */
  margin: 10px auto 0;
}

.highlight-red {
  color: #b14552;
  font-weight: bold; /* Optional: Make it bold for emphasis */
}

.highlight-green {
  color: #2a9d8f;
  font-weight: bold; /* Optional: Make it bold for emphasis */
}

.stats-content-wrapper {
  display: flex;
  gap: 80px;
}

.two-frame-stats {
  display: flex;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

.left-frame {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.left-frame .stats-section-heading {
  grid-column: 1 / 4;
  font-size: 2rem;
  text-align: center;
  margin: 20px 0;
  color: #2c3e50;
  font-weight: 600;
}

.left-frame .left-frame p {
  line-height: 1.6;
}

.left-frame .stats-info-card {
  text-align: center;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0px 4px 8px #ddd;
}

.left-frame .stats-info-card h3 {
  font-size: 1.3rem;
  margin: 10px 0;
}

.left-frame .stats-info-card h4 {
  font-size: 0.9rem;
  margin: 10px 0;
}

.left-frame .stats-info-card p {
  font-size: 0.9rem;
  margin: 10px 0;
}

.left-frame h2 {
  font-size: 2rem; /* Bigger headline for impact */
  color: #b14552;
}

.left-frame .stats-info-card h3 {
  text-align: center;
  color: #b14552;
}

.highlight-red-small {
  color: #b14552;
  font-weight: bold; /* Optional: Make it bold for emphasis */
}

.highlight-green-small {
  color: #2a9d8f;
  font-weight: bold; /* Optional: Make it bold for emphasis */
}

.source-link {
  color: black; /* Change to match your theme */
  text-decoration: underline;
  margin-left: 0.5rem; /* Adds spacing from the text */
  font-size: 0.9rem; /* Slightly smaller size for subtlety */
  font-style: italic; /* Makes the text italic */
  font-weight: normal; /* Ensures the text is non-bold */
}

.source-link:hover {
  color: darkblue; /* Hover effect */
  text-decoration: none; /* Removes underline on hover */
}

.bar-graph {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.bar {
  height: 30px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  font-size: 1rem;
}

.bar.claimed {
  background-color: #2a9d8f;
  width: 25%; /* Example percentage */
}

.bar.unclaimed {
  background-color: #b14552;
  width: 75%; /* Example percentage */
}

.right-frame h3 {
  font-size: 2.2rem;
  margin-bottom: 2rem;
  color: #b14552;
}

.right-frame ul {
  list-style: none;
  padding: 0;
}

.right-frame li {
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
}

.right-frame li strong {
  color: #b14552;
}

/* Adjust for mobile screens (max-width: 768px) */
@media (max-width: 768px) {
  .two-frame-stats {
    flex-direction: column; /* Stack frames vertically */
    gap: 2rem; /* Reduce the gap between frames */
    padding: 1.5rem; /* Adjust padding for smaller screens */
    height: auto; /* Allow dynamic height */
  }

  .left-frame,
  .right-frame {
    padding: 1rem; /* Reduce padding for smaller screens */
    box-shadow: none; /* Optional: Remove shadows for simpler design */
    height: auto; /* Expand dynamically based on content */
  }

  .stats-title {
    font-size: 1.8rem; /* Smaller font size for the title */
    padding: 2rem 1rem; /* Adjust padding */
    min-height: 200px; /* Reduce the minimum height */
  }

  .left-frame h2 {
    font-size: 2rem; /* Reduce headline size */
    margin-bottom: 0.5rem;
  }

  .left-frame p1,
  .left-frame p2 {
    font-size: 1rem; /* Reduce text size */
    margin-bottom: 0.8rem;
  }

  .right-frame h3 {
    font-size: 1.5rem; /* Adjust the size of the right frame title */
    margin-bottom: 1rem;
  }

  .right-frame li {
    font-size: 1rem; /* Reduce font size of list items */
  }

  .bar-graph {
    flex-direction: column; /* Stack bars vertically for better visibility */
    align-items: flex-start; /* Align bars to the left */
  }

  .bar {
    width: 100%; /* Full width for better readability */
    height: 25px; /* Adjust bar height */
  }

  .source-link {
    font-size: 0.8rem; /* Smaller link font size */
  }
}
