/* Pricing.css */
.free-trial-banner {
    text-align: center;
    margin-bottom: 2rem;
}

.trial-badge {
    display: inline-block;
    background-color: #2A9D8F;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    margin-bottom: 1rem;
}

.pricing-container {
    padding: 1rem;
    background-color: #f9f9f9;
    text-align: center;
    max-width: 2000px; /* Increase the container's maximum width */
    margin: 0 auto; /* Center the container horizontally */
}

.pricing-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
}

.pricing-description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #666;
}

.pricing-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Force 3 columns */
    gap: 1.5rem;
    justify-content: center;
    align-items: stretch; /* Ensure all cards have the same height */
}

.card {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 1.5rem;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.card:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.card-header {
    margin-bottom: 1rem;
}

.card-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
}

.card-price {
    font-size: 1.5rem;
    color: #2A9D8F;
}

.card-content {
    margin-bottom: 1rem;
}

.card-features {
    list-style: none;
    padding: 0;
    margin: 0 0 1rem;
    text-align: left;
}

.card-feature {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    color: #555;
}

.feature-icon {
    color: #2A9D8F;
    margin-right: 0.5rem;
}

.card-button-container {
    text-align: center;
}

.card-button {
    background-color: #2A9D8F;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
}

.card-button:hover {
    background-color: #2A9D8F;
}
