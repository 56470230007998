.knowledge-base {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.knowledge-heading {
    font-size: 2rem;
    color: #264653; /* Dark eggplant color for emphasis */
    text-align: center;
    margin-bottom: 20px;
}

.faq-list {
    margin-top: 20px;
}

.faq-item {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
    cursor: pointer;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: #333;
}

.faq-question h3 {
    margin: 0;
    font-size: 1.2rem;
}

.faq-question span {
    font-size: 1.5rem;
    color: #264653; /* Accent color */
}

.faq-answer {
    margin-top: 10px;
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

.faq-example {
    margin-top: 10px;
    font-size: 0.95rem;
    color: #444;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    line-height: 1.5;
}
