.blog-card {
    max-width: 300px;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.blog-card:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.blog-card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.blog-card-content {
    padding: 16px;
}

.blog-card-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 8px;
    color: #2c3e50;
}

.blog-card-date {
    font-size: 0.9rem;
    color: #7f8c8d;
    margin-bottom: 12px;
}

.blog-card-description {
    font-size: 1rem;
    color: #34495e;
}
