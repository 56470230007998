.mission-how-it-works {
  background-color: #264653;
  color: #f9f9f9;
  text-align: center;
  display: flex;
  justify-content: center;
}

.mission-content-container {
  text-align: center;
}

.section-heading {
  color: #2a9d8f;
  padding-bottom: 30px;
}

.section-heading-steps {
  font-size: 2rem;
  font-weight: bold;
  color: #2a9d8f;
  margin-bottom: 30px;
  margin-top: 90px;
}

.mission-text {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 60px;
  color: #f9f9f9;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.how-it-works-section {
  margin-bottom: 40px;
}

.steps-grid {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.step-card {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.step-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.step-icon {
  font-size: 2.5rem;
  color: #264653;
  margin-bottom: 10px;
}

.step-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.step-description {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
}

.cta-container {
  padding: 30px 0 60px; /* Space above the buttons */
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  gap: 20px; /* Space between the buttons */
  justify-content: center; /* Align buttons to the left */
}

/* Responsive Design */
@media (max-width: 1024px) {
  .mission-content-container {
    max-width: 90%; /* Increase width for smaller screens */
    text-align: left; /* Align text to the left for better readability */
  }

  .section-heading {
    font-size: 1.8rem; /* Slightly smaller headings */
  }

  .mission-text {
    font-size: 1.1rem;
    line-height: 1.6;
  }

  .steps-grid {
    gap: 20px; /* Reduce the gap between cards */
    grid-template-columns: repeat(
      auto-fit,
      minmax(200px, 1fr)
    ); /* Adjust column size */
  }
}

@media (max-width: 768px) {
  .mission-how-it-works {
    padding: 40px 20px; /* Reduce padding for smaller screens */
  }

  .section-heading {
    font-size: 1.5rem; /* Smaller headings for mobile */
  }

  .section-heading-steps {
    padding: 50px 30px;
    font-size: 1.5rem; /* Smaller headings for mobile */
  }

  .mission-text {
    font-size: 1rem;
    line-height: 1.5;
  }

  .steps-grid {
    grid-template-columns: 1fr; /* Stack the cards vertically */
    gap: 15px;
    padding-top: 50px;
  }

  .step-card {
    padding: 15px;
  }

  .step-icon {
    font-size: 2rem;
  }

  .cta-container {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center buttons on smaller screens */
    gap: 15px; /* Smaller gap between stacked buttons */
  }
}

@media (max-width: 480px) {
  .mission-content-container {
    max-width: 95%; /* Almost full-width for very small screens */
  }
}
