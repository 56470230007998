/* Common Styles for Tables */
/* DemoComponent Layout */
.demo-component-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Dashboard Section */
.dashboard-section {
  width: 100%;
  margin-bottom: 20px; /* Spacing between dashboard and transaction table */
}

/* Transactions Section */
.transactions-section {
  width: 100%;
  margin-top: 20px; /* Space above the transaction table */
}

.transactions-section table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.transactions-section th,
.transactions-section td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.transactions-section th {
  background-color: #264653; /* Dark green */
  color: white;
}

.transactions-section tr:nth-child(even) {
  background-color: #f9f9f9;
}

table {
  width: 100%;
  margin-bottom: 20px;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  color: #333;
}

thead th {
  background-color: #264653; /* Dark green */
  color: white;
  text-align: left;
  padding: 10px;
  font-weight: bold;
  border-color: white;
}

/* thead tr:first-child th:first-child {
  border-top-left-radius: 12px;
}

thead tr:first-child th:last-child {
  border-top-right-radius: 12px;
} */

.p-column-header-content {
  justify-content: space-between;
}

span[data-pc-section="sort"] {
  margin-left: 10px;
}

tbody tr:nth-child(odd) {
  background-color: #f9f9f9; /* Light gray for alternating rows */
}

tbody tr:nth-child(even) {
  background-color: #ffffff; /* White for alternating rows */
}

td,
th {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd; /* Light border for cells */
  height: 54px;
}

td input {
  width: 90%; /* Input should not overflow */
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

td button {
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #e76f51; /* Button color (red-orange) */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

td button:hover {
  background-color: #d35d41; /* Darker shade on hover */
}

/* Header Section */
h3 {
  color: #264653; /* Dark green */
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}

/* Add Transaction Button */
button.add-transaction {
  padding: 10px 20px;
  font-size: 14px;
  color: white;
  background-color: #2a9d8f; /* Greenish-blue */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

button.add-transaction:hover {
  background-color: #21867a; /* Darker shade on hover */
}

/* Responsive Styles */
@media (max-width: 768px) {
  table {
    font-size: 12px;
  }

  td input {
    width: 100%; /* Full width on small screens */
  }

  button {
    font-size: 12px;
    padding: 5px 8px;
  }

  td,
  th {
    padding: 5px;
    height: 44px;
  }
}
