/* General Frame Styles */
.frame {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  overflow: hidden;
}

/* Filters Container */
.filters {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 8px;
  background-color: #e0f2f1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.filters label {
  font-size: 16px;
  color: #2a9d8f;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.filters input,
.filters select {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 200px;
}

/* Table View (Desktop Only) */
.table-container {
  width: 100%;
  overflow-x: auto; /* Horizontal scrolling for smaller screens */
  margin-bottom: 20px;
}

.transactions-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px; /* Prevent shrinking below usable size */
}

.transactions-table th,
.transactions-table td {
  padding: 10px;
  text-align: left;
  white-space: nowrap; /* Prevent wrapping */
}

.transactions-table th {
  background-color: #264653;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}

.transactions-table td {
  background-color: #ffffff;
  color: #333;
}

.transactions-table tr:nth-child(even) td {
  background-color: #f7f7f7;
}

.transactions-table tr:hover td {
  background-color: #e0f2f1;
  cursor: pointer;
}

/* Pagination Controls */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.pagination button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #264653;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.pagination button:hover {
  background-color: #264653;
  transform: translateY(-2px);
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  font-size: 14px;
  color: #333;
}

/* Header Row for Mobile View */
.transaction-header-row {
  display: flex;
  justify-content: space-between;
  background-color: #264653;
  color: white;
  font-weight: bold;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  text-align: center;
}

/* Individual Header Items */
.transaction-header-row .header-item {
  flex: 1; /* Distribute columns evenly */
  text-align: center;
  font-size: 14px;
}

/* Mobile Card View */
.transaction-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin-bottom: 15px;
  cursor: pointer; /* Indicate interactivity */
}

/* Card Summary Section */
.transaction-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.transaction-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ticker {
  font-weight: bold;
  color: #333;
}

.profit {
  font-weight: bold;
  color: #264653;
}

.loss {
  font-weight: bold;
  color: #b14552;
}

.transaction-date {
  font-size: 14px;
  color: #555;
}

/* Card Details Section */
.transaction-details {
  display: none; /* Hidden by default */
  padding-top: 10px;
  border-top: 1px solid #ddd;
  font-size: 14px;
  color: #333;
}

/* When Card is Expanded */
.transaction-card.expanded .transaction-details {
  display: block; /* Show details when expanded */
}

.transaction-details p {
  margin: 6px 0;
}

/* Responsive Design */
.desktop-view {
  display: block; /* Default display for desktop view */
}

.mobile-view {
  display: none; /* Hidden on larger screens */
}

/* Summary Cards */
.summary-container {
  display: flex;
  gap: 10px;
  margin: 50px 0 20px;
  flex-wrap: wrap; /* Enable wrapping for smaller screens */
}

.summary-card {
  background-color: #fefefe;
  border: 1px solid #299d8f;
  border-radius: 8px;
  padding: 15px;
  transition: all 0.3s ease; /* Smooth resizing */
  box-shadow: 0px 4px 9px #ddd;
}

.summary-card h3 {
  margin: 0;
  font-size: 1.1rem;
  color: #264653;
}

.summary-card .summary-value {
  font-size: 1.1rem;
  color: #264653;
  margin-top: 10px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .desktop-view {
    display: none; /* Hide table on small screens */
  }

  .mobile-view {
    display: block; /* Show card view on small screens */
  }

  .summary-container {
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center-align cards */
    gap: 15px; /* Add more spacing between stacked cards */
  }

  .summary-card {
    width: 90%; /* Take most of the screen width */
    padding: 15px; /* Reduce padding for smaller screens */
  }

  .summary-card h3 {
    font-size: 0.9rem; /* Reduce font size for titles */
  }

  .summary-card .summary-value {
    font-size: 1.2rem; /* Reduce font size for values */
  }
}
