/* General Styles */
.account-manager {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
}

.add-account-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #264653;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.add-account-button:hover {
  background-color: #1f3d4a;
}

/* Table Styles */
.responsive-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  display: table;
}

.responsive-table th,
.responsive-table td {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

button.status-enable:hover {
  background-color: #1f3d4a;
}

button.status-disable,
button.status-enable {
  padding: 7px;
  font-size: 13px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button.status-enable {
  background-color: #264653;
}

button.status-disable {
  background-color: #b14552;
}

button.status-disable:hover {
  background-color: #88313b;
}

/* Card Styles */
.responsive-cards {
  display: none;
}

.account-row {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.account-summary {
  display: flex; /* Enable flexbox for alignment */
  justify-content: space-between; /* Space between the text and icon */
  align-items: center; /* Align items vertically */
  padding: 12px 16px;
  background-color: #f9f9f9;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  transition: background-color 0.2s ease; /* Smooth hover effect */
}

.account-summary:hover {
  background-color: #f0f0f0;
}

/* Account Content */
.account-details {
  text-align: left; /* Align all text to the left */
  margin-top: 15px; /* Add margin at the top */
  padding: 10px 15px; /* Add padding for better spacing */
  background-color: #ffffff; /* Keep a clean background */
  font-size: 14px; /* Adjust font size for readability */
  line-height: 1.6; /* Improve line spacing for multi-line text */
  color: #333; /* Use a darker text color for better readability */
  border-top: 1px solid #ddd; /* Add a subtle border to separate it from the row */
  border-radius: 0 0 8px 8px; /* Round corners for a polished look */
}

.chevron-icon {
  margin-left: 12px; /* Space between text and the chevron icon */
  color: #666; /* Icon color */
  font-size: 14px; /* Icon size */
  vertical-align: middle;
  align-items: end; /* Align icon with text */
  transition: transform 0.2s ease; /* Smooth rotation animation */
}

.button-spacing {
  margin-right: 10px; /* Adjust the spacing as needed */
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .responsive-table {
    display: none;
  }

  .responsive-cards {
    display: block;
  }
}
