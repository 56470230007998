/* Header */
.header {
  display: flex;
  justify-content: center;
  background-color: #264653;
  height: 70px; /* Original height */
  margin: 0;
  border: none;
  box-shadow: none;
  position: relative;
}

.header .content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Logo container */
.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px; /* Adjust logo height */
  width: auto;
  margin-left: -7px;
}

/* Hamburger menu button */
.menu-toggle {
  display: none; /* Hidden by default */
  background: none;
  border: none;
  color: #f9f9f9;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute; /* Absolute positioning for alignment */
  right: 23px; /* Spacing from the right edge */
  top: 50%; /* Vertically center */
  transform: translateY(-50%);
}

/* Navigation Links */
.nav-links {
  display: flex;
  gap: 40px; /* Original spacing */
  align-items: center;
}

.nav-links a {
  text-decoration: none;
  color: #f9f9f9;
  font-weight: 400;
  font-size: 20px;
  position: relative;
  transition: color 0.3s ease;
}

/* Underline hover effect */
.nav-links a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #2a9d8f;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.nav-links a:hover {
  color: #2a9d8f;
}

.nav-links a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.header-buttons {
  display: flex;
  gap: 10px; /* Spacing between buttons */
  align-items: center;
}

.header-buttons a:hover::after {
  display: none;
}

.header-buttons .cta-secondary-button {
  padding: 5px;
  box-shadow: 1px 0 0 var(--right-color), 1px 1px 0 var(--bottom-color),
  2px 1px 0 var(--right-color), 2px 2px 0 var(--bottom-color),
  3px 2px 0 var(--right-color), 3px 3px 0 var(--bottom-color),
  4px 3px 0 var(--right-color), 4px 4px 0 var(--bottom-color),
  5px 4px 0 var(--right-color), 5px 5px 0 var(--bottom-color),
  1px 1px 0 var(--right-color), 1px 1px 0 var(--bottom-color),
  2px 1px 0 var(--right-color), 2px 2px 0 var(--bottom-color),
  3px 2px 0 var(--right-color), 3px 2px 0 var(--bottom-color),
  -5px 20px 40px -8px #454545;
}

.header-buttons .cta-secondary-button:hover {
  transform: translate(1px, 1px);
}

/* Sign Up Button */
.btn-signup {
  background-color: transparent;
  color: #333;
  border: 1px solid #333;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.btn-signup:hover {
  background-color: #333;
  color: #fff;
}

/* Book a Demo Button */
.dropdown-menu .btn-demo {
  background-color: #2a9d8f;
  color: #f9f9f9;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: opacity 0.3s ease;
}

.dropdown-menu .btn-demo:hover {
  opacity: 0.9;
  color: #333;
}

.user-account {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center; /* Vertically align the user icon and username */
}

.user-icon {
  font-size: 1.5rem;
  color: #f9f9f9;
}

.username {
  color: #f9f9f9;
  margin-left: 3px;
}

.active-accounts-profile {
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 8px;
  margin-top: 10px;
  font-family: Arial, sans-serif;
}

.accounts-info-header {
  display: flex;
  gap: 5px;
  border-bottom: 1px solid #299d8f;
  padding-bottom: 5px;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

.accounts-info-header p {
  font-size: 14px;
}

.accounts-info-header p:first-child {
  width: 72%;
}

.accounts-info {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  background: #fbfbfc;
  padding: 10px;
  font-size: 14px;
  border-radius: 8px;
}

.accounts-info p {
  width: 75%;
}

.accounts-seperator {
  width: 100%;
  height: 1px;
  background-color: #299d8f;
  margin-top: 10px;
  opacity: 0.5;
}

/* Mobile Responsive Design */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide links on mobile */
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #264653;
    padding: 20px 0;
    z-index: 999;
  }

  .nav-links.active {
    display: flex; /* Show links when active */
  }

  .menu-toggle {
    display: block; /* Show hamburger on mobile */
  }
}

/* Dropdown Menu */
.dropdown-menu {
  position: absolute;
  top: 2.5rem;
  left: calc(100% - 300px);
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 300px;
  z-index: 1000;
}

.dropdown-menu a,
.dropdown-menu button {
  padding: 10px;
  color: #333;
  text-decoration: none;
  font-size: 0.9rem;
  text-align: left;
  cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0; /* Match the main background */
}
