.terms-and-conditions {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px auto;
    max-width: 800px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333;
}

.terms-and-conditions h1 {
    font-size: 2rem;
    color: #264653;
    text-align: center;
    margin-bottom: 20px;
}

.terms-and-conditions h2 {
    font-size: 1.5rem;
    color: #2a9d8f;
    margin-top: 20px;
    margin-bottom: 10px;
}

.terms-and-conditions p {
    margin-bottom: 15px;
}

.terms-and-conditions ul {
    padding-left: 20px;
    margin-bottom: 20px;
}

.terms-and-conditions ul li {
    margin-bottom: 10px;
}

.terms-and-conditions hr {
    border: none;
    border-top: 1px solid #ddd;
    margin: 20px 0;
}

.terms-and-conditions address {
    font-style: normal;
    line-height: 1.4;
}

.terms-and-conditions strong {
    color: #333;
}
