.features-section {
  display: flex;
  flex-direction: column;
  gap: 50px;
  background-color: #fff; /* Light background for contrast */
  align-items: center;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background-color: #fff;
  margin-bottom: 40px;
}

.feature-item.reverse {
  flex-direction: row-reverse;
}

.feature-text {
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 3px 0px 30px 0px #ecececdb;
}

.feature-text h2 {
  font-size: 1.8rem;
  color: #264653;
  margin-bottom: 10px;
}

.feature-text h4 {
  font-size: 1.2rem;
  color: #2a9d8f;
  margin-bottom: 20px;
}

.feature-text p {
  font-size: 1rem;
  color: #333;
  line-height: 1.5;
}

.feature-image {
  flex: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-image img {
  width: 80%;
  height: auto;
  border-radius: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .feature-item {
    flex-direction: column;
  }

  .feature-item.reverse {
    flex-direction: column;
  }

  .feature-text {
    text-align: center;
  }

  .feature-image img {
    width: 100%;
  }
}
