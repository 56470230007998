/* Hero Section */
.hero-section {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #264653;
  overflow: hidden;
  min-height: 75vh;
  position: relative;
}
.hero-bottom-border {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 18px;
  background-color: #2a9d8f;
}
.hero-section .content-wrapper {
  display: flex;
  flex-direction: row; /* Default to side-by-side layout */
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}
.hero-text {
  max-width: 50%;
  color: #f9f9f9;
  text-align: left; /* Ensure text is left-aligned */
}

.hero-text ul {
  list-style: none;
  margin-bottom: 35px;
}

.hero-text li {
  display: flex;
  align-items: center;
  margin-bottom: -30px;
}

.hero-text ul {
  list-style: none;
  margin-bottom: 35px;
}

.hero-text li {
  display: flex;
  align-items: center;
  margin-bottom: -30px;
}

.hero-text h1 {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 10px;
  color: #f9f9f9;
  text-align: left; /* Left-align the heading */
}
.hero-text h2 {
  font-size: 1.5rem;
  margin: 25px 0;
  color: #f9f9f9;
  text-align: left; /* Left-align the subheading */
}
.hero-text p {
  font-size: 1rem;
  line-height: 1.6;
  color: #f9f9f9;
  padding-right: 175px;
  text-align: justify; /* Left-align the paragraph */
}

.hero-slideshow {
  flex: 1;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  align-self: flex-end;
}
.slideshow-image {
  width: 120%;
}
.hero__cta-container {
  margin-top: 30px; /* Space above the buttons */
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  gap: 20px; /* Space between the buttons */
  justify-content: flex-start; /* Align buttons to the left */
}
.cta-button,
.cta-secondary-button {
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
  --top-color: #cccccc66;
  --bottom-color: #9999994e;
  --right-color: #dddddd60;
  background: var(--top-color);
  transition: box-shadow 0.2s, transform 0.15s;
  box-shadow: 1px 0 0 var(--right-color), 1px 1px 0 var(--bottom-color),
  2px 1px 0 var(--right-color), 2px 2px 0 var(--bottom-color),
  3px 2px 0 var(--right-color), 3px 3px 0 var(--bottom-color),
  4px 3px 0 var(--right-color), 4px 4px 0 var(--bottom-color),
  5px 4px 0 var(--right-color), 5px 5px 0 var(--bottom-color),
  6px 5px 0 var(--right-color), 6px 6px 0 var(--bottom-color),
  7px 6px 0 var(--right-color), 7px 7px 0 var(--bottom-color),
  8px 7px 0 var(--right-color), 8px 8px 0 var(--bottom-color),
  -5px 20px 40px -8px #454545;
}
.cta-button:hover,
.cta-secondary-button:hover {
  outline: 0;
  box-shadow: 1px 0 0 var(--right-color), 1px 1px 0 var(--bottom-color),
  2px 1px 0 var(--right-color), 2px 2px 0 var(--bottom-color),
  3px 2px 0 var(--right-color), 3px 3px 0 var(--bottom-color),
  4px 3px 0 var(--right-color), 4px 4px 0 var(--bottom-color),
  -5px 5px 12px -8px #454545;
  transform: translate(3px, 3px);
}

/* Primary button */
.cta-button {
  background-color: #2a9d8f;
  color: white;
  border: 2px solid transparent;
}
/* Secondary button */
.cta-secondary-button {
  background-color: white;
  color: #2a9d8f;
  border: 2px solid transparent;
}
/* Responsive Design */
@media (max-width: 768px) {
  .hero__cta-container {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center buttons on smaller screens */
    gap: 15px; /* Smaller gap between stacked buttons */
  }
  .cta-button,
  .cta-secondary-button {
    width: 100%; /* Full-width buttons on mobile */
    text-align: center; /* Center text inside the buttons */
  }
}
/* Responsive Design */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column; /* Stack text and image vertically */
    height: auto; /* Adjust height for stacked layout */
    padding: 20px; /* Reduce padding */
  }
  .hero-text {
    max-width: 100%; /* Use full width */
    text-align: center; /* Center-align text for better readability */
    padding: 10px;
  }
  .hero-text h1 {
    font-size: 2rem; /* Adjust heading size */
  }
  .hero-text h2 {
    font-size: 1.2rem; /* Adjust subheading size */
  }
  .hero-text p {
    font-size: 0.9rem; /* Adjust paragraph font size */
  }
  .hero-slideshow {
    max-width: 100%; /* Use full width */
    justify-content: center;
    margin-top: 40px;
    margin-left: 50px; /* Add space between text and image */
  }
  .slideshow-image {
    width: 100%; /* Ensure the image fits within the container */
    max-height: 300px; /* Limit the image height for mobile screens */
  }
}