/* General App styling */
.App {
  text-align: center;
}

.content-wrapper {
  max-width: 1600px;
  width: 100%;
  padding: 0 20px;
}

.home-page__main {
  width: 100%;
}

/* Container for the full application layout */
/* Ensure the AppContainer takes the full height of the viewport */
.AppContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Ensure Content takes up the remaining space */
.Content {
  flex: 1; /* Allows Content to stretch and push the footer down */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content at the top */
}

/* Footer styling */
footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-shrink: 0; /* Prevents footer from shrinking */
  padding: 20px;
  text-align: center;
  background-color: #264653;
  color: #fff;
}

/* Header styling (optional) */
.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Link styling */
.App-link {
  color: #61dafb;
}

/* Logo animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
