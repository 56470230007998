/* Dashboard Container */
.dashboard-container {
  padding: 40px 0;
  display: flex;
  justify-content: center;
}

.dashboard-container--no-accounts {
  flex: 1 1;
  align-items: center;
}

.dashboard-container--no-accounts .primary-cta-wrapper-dashboard {
  justify-content: center;
  margin-top: 20px;
}

.dashboard-container--no-accounts .primary-cta-wrapper-dashboard button {
  width: 250px;
}

.dashboard-container--no-accounts p {
  text-align: center;
  font-size: 18px;
  margin: 10px 0;
}

.primary-cta-wrapper-dashboard {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}

/* Tabs */
.tabs {
  display: flex;
  margin-bottom: 30px;
  gap: 10px;
  flex-wrap: wrap;
  border-bottom: 1px solid #299d8f;
  box-shadow: -2px 26px 28px -18px #858585d1;
}

.tabs button {
  padding: 15px 25px;
  border: none;
  background-color: white;
  color: #264653;
  font-size: 1rem;
  cursor: pointer;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tabs button.active {
  background-color: #e0f2f1;
  color: #264653;
  box-shadow: 0px 0px 4px #2746536e;
}

.tabs button:not(.active):hover {
  background-color: #e0f2f1;
}

/* Tooltip Styles */
.tooltip-container {
  position: relative;
  display: inline-block;
  width: auto;
}

.tooltip-container .tooltip {
  visibility: hidden;
  background-color: #264653;
  color: white;
  text-align: center;
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the button */
  left: 50%;
  transform: translateX(-50%);
  width: 220px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tooltip-container:hover .tooltip {
  visibility: visible;
}

.tab-content .p-datatable-header {
  border-radius: 10px;
  margin-bottom: 5px;
  background: #e0f2f1;
}

.tab-content .p-paginator-bottom.p-paginator.p-component {
  padding-bottom: 20px;
}

.tab-content .p-icon-field {
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-items: center;
  gap: 10px;
}

.tab-content .p-icon-field svg {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 194px;
}

.tab-content .p-icon-field input {
  padding: 11px;
  border-radius: 4px;
  border: 1px solid #636060;
  padding-left: 35px;
}

.datatable-header-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.datatable-filters-wrapper {
  display: flex;
  align-items: center;
}

.datatable-filters-wrapper select {
  display: block;
  margin-top: 5px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #b1b1b1;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px;
  width: 100px;
  height: 40px;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background-image: url(../assets/down-arrow.svg);
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: calc(100% - 16px);
}

button.p-button.p-component.p-button-outlined {
  height: 43px;
  padding: 0 10px;
  border: none;
  background-color: transparent;
  color: #264653;
  display: flex;
  gap: 10px;
}

.p-datatable svg.p-icon.p-sortable-column-icon {
  color: #e0f2f1;
}

.tab-content thead tr th {
  border-color: transparent;
}

.tab-content thead tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

.tab-content thead tr:first-child th:last-child {
  border-top-right-radius: 8px;
}

.tab-content tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.tab-content tbody tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.tab-content .p-hidden-space {
  visibility: visible;
}

.tab-content .p-column-filter-row .p-column-filter-element {
  margin-right: 10px;
}

.tab-content button.p-column-filter-clear-button.p-link {
  color: white;
  width: auto;
}

.tab-content .p-column-filter.p-multiselect.p-component.p-inputwrapper {
  padding: 2px 0px 2px 5px;
  background-color: transparent;
}

.tab-content .p-column-filter.p-multiselect.p-component.p-inputwrapper div,
.tab-content .p-column-filter.p-multiselect.p-component.p-inputwrapper svg {
  color: white;
}

.p-multiselect-panel ul li {
  display: flex;
  gap: 5px;
  padding: 10px 5px;
}

.p-multiselect-panel .p-multiselect-header {
  padding: 5px;
  padding-right: 0;
}

.p-column-filter.p-fluid.p-column-filter-menu svg {
  color: white;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter.p-fluid.p-column-filter-menu button:hover {
  background-color: transparent;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open svg,
.p-column-filter.p-fluid.p-column-filter-menu button:hover svg {
  color: #299d8f !important;
}

.p-column-filter.p-multiselect.p-component.p-inputwrapper {
  padding: 10px;
}

button.p-button.p-component.p-button-sm:last-child {
  padding: 5px;
  margin-right: 5px;
  background: #299d8f;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: transparent;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active svg,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover svg {
  color: #299d8f !important;
}

.p-row-toggler.p-link {
  background-color: #264653;
}

.p-datatable-row-expansion h5 {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .tabs {
    display: grid;
    grid-template-columns: 1fr; /* Single column on mobile */
  }

  .tabs button {
    width: 100%; /* Make buttons full width */
    font-size: 14px;
  }

  .tooltip-container .tooltip {
    width: 180px; /* Adjust tooltip width for smaller screens */
    font-size: 12px;
  }

  .mobile-row-expander .p-row-toggler.p-link {
    background-color: transparent;
    padding: 0;
    color: #636060;
    width: 10px;
  }
  .expand-row {
    display: flex;
    gap: 5px;
    margin: 5px 0;
    text-align: left;
  }
  .expand-row h4 {
    width: 105px;
  }
}
